.about-full-width-bg {
    width: 100%;
    margin: 0px;
    background-color: white;
    padding: 30px 0; 
  }
  
  .about-col-content {
    justify-content: center;
    padding-top: 30px;
    padding-bottom: 50px;
  }
  
  .about-heading {
    font-size: 2.1em;
    padding-bottom: 20px;
  }
  
  .about-img {
    padding-top: 120px;
    padding-bottom: 50px;
  }
  