
.sticky {
    background-color: rgb(237, 233, 245) !important;
    transition: all 0.3s ease-out 0s !important;
    box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171) !important;
    backdrop-filter: blur(15px) !important;
  }
  
  .navbar {
    position: fixed !important;
   
    transition: all 0.3s ease-out 0s !important;
    /* padding: 0.3rem 2rem !important; */
    font-size: 1.2rem !important;
    
  }
  
  .navbar-toggler {
    position: relative !important;
    background-color: transparent !important;
    border-color: transparent !important;
  }
  
  .navbar-toggler span {
    display: block !important;
    background-color: #68187a !important;
    height: 4px !important;
    width: 27px !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    transform: rotate(0deg) !important;
    left: 0 !important;
    opacity: 1 !important;
  }
  
  .navbar-toggler:focus,
  .navbar-toggler:active {
    outline: 0 !important;
  }
  
  .navbar-toggler span:nth-child(1),
  .navbar-toggler span:nth-child(3) {
    transition: transform 0.35s ease-in-out !important;
    transition: transform 0.35s ease-in-out !important;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: absolute !important;
    left: 12px !important;
    top: 10px !important;
    transform: rotate(135deg) !important;
    opacity: 0.9 !important;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 12px !important;
    visibility: hidden !important;
    background-color: transparent !important;
  }
  
  .navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: absolute !important;
    left: 12px !important;
    top: 10px !important;
    transform: rotate(-135deg) !important;
    opacity: 0.9 !important;
  }
  
  @media (max-width: 767px) {
    .navbar {
      padding: 0.3rem 1rem !important;
      font-size: 1.4rem !important;
      background-color: rgb(237, 233, 245);
    }
    .navbar-nav .nav-item a::after {
      display: none !important;
    }
  }
  .navbar-brand {
    color: rgb(250, 250, 250) !important;
  }
  
  .logo {
    height: 3.3em !important;
    width: 8.9em !important;
  }
  
  .navbar-nav .nav-link {
    color: white;
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  
  .nav-link {
    padding: 0.8rem 1rem !important;
  }
  
  @media (max-width: 767px) {
    .nav-link {
      padding: 0.7rem 1rem !important;
      color: white;
    }
  }
  
  .navbar-nav .nav-item {
    position: relative;
    margin-left: 20px;
  }
  
  .navbar-nav .nav-item a {
    font-weight: 400;
    transition: all 0.3s ease-out 0s;
    position: relative;
    z-index: 1;
  }
  
  .navbar-nav .nav-item a::after {
    content: "";
    position: relative;
    display: block;
    height: 5px;
    width: 0;
    border-radius: 16px;
    background: #68187a;
    bottom: 1px;
    left: 0;
    z-index: -1;
    transition: all 0.3s ease-out 0s;
  }
  
  .navbar-nav .nav-item a:hover::after {
    width: 100%;
  }
  


  