


.git-section-layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    font-family: Arial, sans-serif;
    max-width: 1100px;
    margin: auto;
    padding: auto;
}

.git-upper-container {
    background-color: #e0f7fa;
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    padding: 0 50px;
}

.git-vectorz-image {
    flex: 0 0 50%;
    display: flex;
    justify-content: flex-start;
}

.git-vectorz-image img {
    width: 100%;
    height: auto;
}

.cardz-container {
    position: absolute;
    top: 190px;
    right: 50px;
    z-index: 10;
    width: 350px;
    background-color: white;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 20px;
}

.cardz h2 {
    font-size: 1.8rem;
    margin-bottom: -60px;
    color: black;
    font-weight: 700;
    text-align: left;
}

.cardz form {
    display: flex;
    flex-direction: column;
    margin-top: 100px;
}

.input-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.input-group input,
.input-group select {
    width: 48%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.cardz textarea {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 100%;
}

.cardz button {
    background-color: #68187a;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    align-self: flex-start;
}

.git-lower-container {
    background-color: #141F39;
    padding: 40px 0;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.git-footer-row {
    color: white;
    margin: 20px;
    text-align: left;
}

.git-footer-logo img {
    width: 15%;
    margin-left: 65px;
    height: auto;
    margin-bottom: 10px;
}

.git-social-icons {
    display: flex;
    gap: 10px;
    font-size: 1.5rem;
}

.git-social-icons svg {
    color: white;
}

.git-footer-row ul {
    list-style: none;
    padding: 0;
}

.git-footer-row ul li {
    margin-bottom: 10px;
    font-size: 1rem;
}

@media only screen and (max-width: 600px) {
  .git-upper-container {

      background-color: transparent;
    height: auto;
    padding: 20px;
 
    justify-content: center;
    flex-direction: column;
  }
  .git-footer-row {
      color: white;
      margin: 20px;
  text-align: center;
    }

 

  .cardz-container {

 background-color: #ffffff;
    position: relative;
    top: auto;
    right: auto;
    margin: 20px;
    width: 80%;
    max-width: 400px;
    box-shadow: none;
  }

  .git-lower-container {
    flex-direction: column; 
    align-items: center;
   width: 80%;
    padding: 20px;
  }

  .git-footer-logo img {
    margin: 0 auto 10px auto;
  }

  .git-footer-row {
    margin: 10px;
    width: 100%;
    max-width: 300px;
  }

  .git-social-icons {
    justify-content: center;
    margin-top: 20px;
  }
}


@media (min-width: 600px) and (max-width: 768px) {
.git-upper-container {

  background-color: transparent;
height: auto;
padding: 20px;

justify-content: center;
flex-direction: column;
}
.git-footer-row {
  color: white;
  margin: 20px;
text-align: center;
}



.cardz-container {
width: 80%;
position: relative;
top: auto;
right: auto;
margin: 20px;


box-shadow: none;
}

.git-lower-container {
flex-direction: column; 
align-items: center;
width: 80%;
padding: 20px;
}

.git-footer-logo img {
margin: 0 auto 10px auto;
width: 30%;

}

.git-footer-row {
margin: 10px;
width: 100%;
max-width: 300px;
}

.git-social-icons {
justify-content: center;
margin-top: 20px;
}
}









