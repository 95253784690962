.home-section {
    /* background-color: #fffdfd; */
    background-color: white;
    padding: 50px 0;
    text-align: center;
  }
  
  .home-section__content {
    /* max-width: 1100px;
    margin: 0 auto; */
    margin: 0 auto; 
    
    max-width: 1100px;
    /* background-color: #f5f5f5; */
       /* background-color: rgb(237, 233, 245);  */
  }
  
  .home-card-section{
   
    background-color: #f5f5f5;
  }
  .home-section__title {
    font-size: 2.6em;
  
    color: #141f39;
    margin-bottom: 40px;
  }
  


  
  .home-section__title--highlight {
    color: #68187a;
  }
  
  .home-section__team-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  .home-section__team-item {
    flex: 0 0 auto;
    width: 300px;
    margin: 20px;
  }
  
/* .home-section__technologies {
  margin-top: 50px;
  text-align: center;
} */

.home-section__technologies {
  margin-top: 50px;
  /* max-width: 1100px; */
  max-width: 100%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  background-color: white;
}


  .home-section__subheading {
    font-size: 2em;
    color: #141f39;
    margin-bottom: 30px;
  }
  




  
