


.software-dev-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; 
  gap: 20px;
  width: 100%;
  max-width: 1200px; 
  margin: 60px auto; 
  box-sizing: border-box; 
}
.Softwareheading{
  margin-top: 20px;
  color: #1d2c4e;
}
.software-dev-box {
  position: relative;
  width: 100%; 
  max-width: 330px;
  height: 280px; 
  perspective: 1000px;
  box-sizing: border-box;
  margin: 0; 
}

.software-dev-box .front, .software-dev-box .back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  transition: transform 0.6s;
}

.software-dev-box .front {
  background-color: #f8f9fa;
}

.software-dev-box .back {
  
  background-color: #141F39; 
  color: white;
  transform: rotateY(180deg);
}
.software-dev-box .back h3{
 font-size: 20px;
}
.software-dev-box .back p{
  font-size: 13px;
  text-align: center;
 }
.software-dev-box:hover .front {
  transform: rotateY(180deg);
}

.software-dev-box:hover .back {
  transform: rotateY(0);
}

.software-dev-box .box-image {
  position: absolute;
  bottom: -10px;
  left: 10px;
  width: 250px;
  height: 250px;
}

.software-dev-box .title {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 18px; 
  font-weight: bold;
  color: inherit;
}

.software-dev-box .box-number {
  position: absolute;
  bottom: 10px;
  right: 20px;
  font-size: 26px; 
  font-weight: bold;
  color: inherit;
}

.software-dev-box.box-1 .front {
  background-color: #f8c3c3;
}

.software-dev-box.box-2 .front {
  background-color: #fadfbe;
}

.software-dev-box.box-3 .front {
  background-color: #fdffb6;
}

.software-dev-box.box-4 .front {
  background-color: #caffbf;
}

.software-dev-box.box-5 .front {
  background-color: #bdf6fc;
}

.software-dev-box.box-6 .front {
  background-color: #c7dafa;
}

@media (max-width: 767px) {
  .software-dev-box {
    flex: 0 1 calc(100% - 5px);
  }
}
