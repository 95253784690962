
   .new-project-section {
    margin-top: 80px;
    padding: 50px 20px;
    /* background-color:#f5f5f5; */
    background-color: white;
    text-align: center;
  }
  
  .new-project-heading {
    font-size: 2.3em;
    color: #141f39;
    font-weight: 500;
    margin-bottom: 20px;
  }
  
  .new-project-cards-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    width: 100%;
    padding: 10px 50px;
    position: relative;
    overflow: hidden;
  }
  .new-project-card {
  flex: 0 0 310px;
  height: auto;
  box-sizing: border-box;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1); 
  position: relative;
  overflow: hidden;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
}

  .new-card-img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-top: -30px;
  }
  
  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -50px;
    align-items: center;
    width: 100%;
    
  }
  
  .new-card-title {
    font-size: 1.2em;
    margin-bottom: 10px;
    font-weight: 600;
    color: #623686;
    text-align: center;
  }
  
  .new-card-text {
    font-size: 1em;
    color: #333;
    margin-bottom: 15px;
    text-align: justify;
    width: 100%;
  }
  
  .new-card-link {
    display: inline-block;
    background-color: #623686;
    color: white;
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, box-shadow 0.3s;
    text-align: center;
    margin-top: auto; 
  }
  
  .new-card-link:hover {
    background-color: #7f5b8a;
    color: white;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3);
  }
  
  .arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2.3em;
    color: #623686;
    cursor: pointer;
    z-index: 10;
  
  }
  
  .arrow-left {
    left: 10px;
  }
  
  .arrow-right {
    right: 10px;
  }
  
  @media (max-width: 768px) {
    .new-project-cards-container {
      flex-direction: column;
      gap: 40px;
      margin-top: 70px;
      padding: 0;
    }
    .new-project-card {
      flex: 0 0 50%;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1); 
    }
    .new-project-card {
      width: 380px ;
      height: auto;
      margin: 0 auto;
    }
  
    .arrow {
      display: none;
    }
  }
  
  @media (min-width: 769px) and (max-width: 1024px) {
    .new-project-cards-container {
      justify-content: center;
      flex-wrap: wrap;

      gap: 10px;
    }
  
    .new-project-card {
      flex: 0 0 45%;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1); 
    }
  
    .arrow {
      display: block;
    }
  }










  @media (max-width: 360px) {
    .new-project-cards-container {
      flex-direction: column;
      padding: 0; /* Remove extra padding */
      width: 100%; /* Full width */
      margin: 0 auto; /* Center the container */
    }
  
    .new-project-card {
      width: 100%; /* Ensure cards take full width */
      margin: 0 auto; 
    }
  
    .arrow {
      display: none;
    }
  }



  @media (max-width: 375px) {
    .new-project-cards-container {
      flex-direction: column;
      padding: 0; 
      width: 100%;
      margin: 0 auto;
    }
  
    .new-project-card {
      width: 100%;
      margin: 0 auto;
    }
  
    .arrow {
      display: none;
    }
  }
  
  







