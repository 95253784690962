@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@500&display=swap");
body {
  margin: 0;
  font-family: "Raleway", serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-image: var(--image-gradient), url(./Assets/Home/home-bg1.jpg);
  background-position: top center;
  background-size: cover !important;
  background-repeat: no-repeat;
}
