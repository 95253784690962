
.tech-icons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 50px;
  max-width: 1100px;
  margin: auto;
  padding: auto;
  align-items: center;
}

.tech-icons {
  color: #68187a;
  font-size: 4.5em;
  margin: 15px;
  padding: 10px;
  opacity: 0.93;
  border: 1.7px solid rgba(9, 5, 29, 0.171);
  vertical-align: middle;
  text-align: center;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px; 
  height: 120px; 
  box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137);
  overflow: hidden;
  transition: all 0.4s ease 0s;
}

.tech-icons:hover {
  transform: scale(1.05);
  overflow: hidden;
  border: 2.2px solid rgba(9, 5, 29, 0.171);
}

.tech-icon-images {
  padding: 20px;
  line-height: 1.6;
}

@media (max-width: 1024px) {
  .tech-icons {
    font-size: 3.5em; 
    width: 120px; 
    height: 120px;
  }
}

@media (max-width: 767px) {
  .tech-icons {
    margin: 10px;
    font-size: 2.5em; 
    width: 100px; 
    height: 100px;
  }
}
