html {
  --section-background-color: linear-gradient(
    to bottom left,
    rgba(255, 255, 255, 0.548),
    rgba(255, 255, 255, 0.774)
  );

  --image-gradient: linear-gradient(
    to bottom left,
    rgba(255, 255, 255, 0.685),
    rgba(255, 255, 255, 0.459)
  );

  --imp-text-color: #c770f0;
}

.purple {
  color: #68187a !important;
}
.purples {
  color: #c770f0!important;
}

button:focus {
  box-shadow: none !important;
}


#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: white;
  background-image: url(./Assets/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}



::-webkit-scrollbar {
  width: 7px;
}


::-webkit-scrollbar-track {
  background: white;
}


::-webkit-scrollbar-thumb {
  background: rgba(178, 121, 216, 0.959);
  border-radius: 12px;
}


::-webkit-scrollbar-thumb:hover {
  background: rgba(222, 130, 235, 0.911);
  border-radius: 12px;
}




.wave {
  animation-name: wave-animation; 
  animation-duration: 2.1s;
  animation-iteration-count: infinite; 
  transform-origin: 70% 70%;
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } 
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } 
  100% {
    transform: rotate(0deg);
  }
}
#tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
}

.home-header {
  padding-top: 80px !important;
  
}

.home-section {
  /* background-color: rgb(237, 233, 245); */
  position: relative;
  /* z-index: -1; */
  /* background-image: var(--image-gradient), url(./Assets/home-bg.jpg); */
  background-position: top center;
  background-size: cover !important;
  background-repeat: no-repeat;
  padding-bottom: 30px !important;
  padding-top: 30px !important;
}

.home-content {
  padding: 9rem 0 2rem !important;
  color: #141F39;
  text-align: left;
}

.heading {
  font-size: 2.4em !important;
  padding-left: 50px !important;
}

.heading-name {
  font-size: 2.5em !important;
  padding-left: 45px !important;
}

.main-name {
  color: #68187a;
}

.Typewriter__wrapper {
  font-size: 2.5em !important;
  color: #68187a !important;
  font-weight: 600 !important;
}
.Typewriter__cursor {
  font-size: 2.4em !important;
  color: #68187a !important;
}

@media (max-width: 767px) {
  .Typewriter__wrapper {
    font-size: 1.4em !important;
    font-weight: 500 !important;
    position: absolute !important;
  }
  .Typewriter__cursor {
    display: none !important;
  }
}

.myAvtar {
  justify-content: center !important;
  padding-top: 9em !important;
}

@media (max-width: 767px) {
  .myAvtar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
}

.home-about-section {
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 70px !important;
}

.home-about-description {
  color: #141F39;
  padding-top: 100px !important;
  padding-bottom: 20px !important;
  text-align: center;
}

.home-about-body {
  padding-top: 50px;
  font-size: 1.2em !important;
  text-align: left;
}

.home-about-social {
  text-align: center !important;
  padding-top: 25px;
  color:#141F39;
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 15px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: rgba(255, 255, 255, 0.972) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #68187a;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #801f95;
}

.home-social-icons:hover {
  color: #87209e;
  box-shadow: 0 0 5px #87209e;
  text-shadow: 0 0 2px #87209e;
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.icon-colour {
  color: #700c86 !important;
}



.footer {
  background-color: #141F39;

  bottom: 0;
  left: 0;
  width: 100%; 
  padding-top: 10px !important;
  padding-bottom: 8px !important;
  z-index: 1000;
}

.footer-copywright {
  text-align: center !important;
}

.footer-body {
  z-index: 1;
  text-align: center !important;
}

@media (max-width: 767px) {
  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 1em;
  color: white !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}

.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}

.blockquote-footer {
  color: #68187a !important;
}


.project-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
}

.project-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.project-card-view {
 
  color:#141F39;
  background-color: transparent !important;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}
.project-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 2px 2px 3px rgba(95, 95, 95, 0.171) !important;
}

.blog-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.blog-card-view {
  background-color: transparent !important;
  box-shadow: 0 3px 3px 2px rgba(145, 77, 161, 0.459) !important;
  color:#141F39;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}

.blog-link {
  color: #141F39;
  text-decoration: none !important;
}

.blog-link:hover {
  cursor: pointer !important;
}

.blog-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 3px 3px 5px rgba(155, 88, 173, 0.65) !important;
}

.card-img-top {
  padding: 15px !important;

  border-radius: 10px !important;
}

.blog-img {
  padding: 0px !important;

  border-radius: 0px !important;
}

.btn-primary {
  color:#141F39;
  background-color: #623686 !important;
  border-color: #623686 !important;
}

.btn-primary:hover {
  color: #141F39;
  background-color: #6d20c5d7 !important;
  border-color: #6d20c5d7 !important;
}
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.project-heading {
  color: #141F39;
  font-size: 2.3em !important;
  font-weight: 500 !important;
  padding-top: 10px !important;
}



.about-section {
  position: relative !important;
  padding-top: 80px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color:#141F39;

}

.quote-card-view {
  border: none !important;
  color: #141F39;
  background-color: transparent !important;
}

.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

@media (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
  .home-content {
    padding: 0 0 0 !important;
    color: #141F39;
    text-align: left;
  }
}


.resume-section {
  position: relative !important;
  padding-top: 110px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color:#141F39;
}

.resume {
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: center;
}

.resume-left {
  padding-right: 80px !important;
}

.resume-right {
  padding-left: 80px !important;
}

@media (max-width: 767px) {
  .resume-left {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .resume-right {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}
.resume .resume-title {
  font-size: 2em;
  font-weight: 700;
  padding-top: 30px;
  padding-bottom: 30px;
}

.resume .resume-item {
  padding: 0 0 10px 25px;
  margin-top: -2px;
  border-left: 2px solid #8a49a8;
  position: relative;
}

.resume .resume-item .resume-title {
  line-height: 18px;
  font-size: 0.9em;
  background: #5234795d;
  padding: 8px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
}

.resume .resume-item ul {
  padding-left: 20px;
  text-align: justify;
}

.resume .resume-item ul li {
  padding-bottom: 10px;
  list-style: none;
}

.resume .resume-item:last-child {
  padding-bottom: 0;
}

.resume .resume-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #fff;
  border: 2px solid #8a49a8;
}

.like-item {
  padding-top: 10px !important;
  font-size: 1.1em !important;
  font-family: sans-serif !important;
}

.like-btn {
  background-color: #934cce5e !important;
  border-color: #934cce5e !important;
  padding: 0.25rem 0.98rem !important;
  border-radius: 5px !important;
  line-height: 1.4 !important;
  transition: 0.3s ease !important;
}

.like-btn:hover {
  transform: translateY(-2px) !important;
  background-color: #a24dd386 !important;
  border-color: #a24dd386 !important;
}

.animate-like {
  animation-name: likeAnimation;
  animation-fill-mode: forwards;
  animation-duration: 0.85s;
}
@keyframes likeAnimation {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.fork-btn {
  font-size: 1.1em !important;
  padding-top: 10px !important;
}

.fork-btn-inner {
  line-height: 1.4em !important;
  background-color: #934cce5e !important;
  padding: 0.25rem 1.1rem !important;
  vertical-align: middle !important;
  text-align: center !important;
}

.fork-btn-inner:hover {
  transform: translateY(-2px) !important;
  background-color: #63636386 !important;
  border-color: #6b6b6b86 !important;
}
.fork-btn-inner::after {
  display: none !important;
}


.team-member-card-view {
  box-shadow: 0 4px 5px 3px rgba(119, 53, 136, 0.459) !important;
  color:#141F39;
  background-color: transparent !important;
  opacity: 0.9 !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
  
}

.team-member-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 4px 4px 5px rgba(139, 139, 139, 0.561) !important;
}

.team-member-card-view .card-img-top {
  padding: 20px !important;
  opacity: 0.8 !important;
  border-radius: 10px !important;
}

.team-member-card-view .card-body {
  padding: 15px !important;
}

.team-member-card-view .card-title {
  font-size: 1.5em !important;
  color: #141F39;
}

.project-card-view .card-subtitle {
  font-size: 1.2em !important;
  color:#141F39;
}

.team-member-card-view .card-text p {
  color: #141F39;
  margin-bottom: 10px !important;
}

.team-member-card-view .social-icons {
  margin-top: 15px !important;
  display: flex !important;
  justify-content: center !important;
}

.team-member-card-view .social-icons a {
  margin: 0 10px !important;
  color: #ffffff !important;
  font-size: 1.5em !important;
  transition: color 0.3s ease !important;
}

.team-member-card-view .social-icons a:hover {
  color: #999999 !important;
}


.about-section {
  /* background-color: #f5f5f5; */
  text-align: center;
  background-color: rgb(202, 199, 207);
  /* padding: 50px 20px; */
}

.about-section h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.stats-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.stat-box {
 
  margin: 15px !important;
  padding: 15px !important;
  opacity: 0.93 !important;
  border: 1.7px solid rgba(200, 137, 230, 0.637) !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 5px !important;
  display: table !important;
  box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
  width: 200px;

}

@media (max-width: 767px) {
  .stat-box {
    margin: 10px !important;
  }
}


.start-box-images {
  padding: 20px !important;
  line-height: 1.6 !important;
}
.stat-box:hover {
  transform: translateY(-5px);
  transform: scale(1.05) !important;
  overflow: hidden !important;
  border: 2.2px solid rgba(197, 115, 230, 0.883) !important;
}

.stat-number {
  font-size: 3em;
  color: rgba(91, 33, 114, 0.883) !important;
}

.stat-label {
  font-size: 1em;
  color:  #141F39;
  
}


.team-member-card-view {
  width: 250px;
  margin: 10px;
}

.team-member-card-view .card-img-top {
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin: 0 auto;
}


.testimonials-section {
  text-align: center;
  padding: 50px 20px;

  color: white;
}

.section-title {
  font-size: 2em;
  margin-bottom: 20px;
  color: var(--imp-text-color);
}

.testimonials-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.testimonial-card {
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 250px;
  text-align: center;
  transition: transform 0.3s ease;
}

.testimonial-card:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  
}

.testimonial-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px;
}

.testimonial-content {
  color: #141F39;
}

.testimonial-text {
  font-size: 1em;
  margin-bottom: 10px;
  color:#141F39;

}

.testimonial-name {
  font-size: 1.1em;
  font-weight: bold;
  color: var(--imp-text-color);
}



.project-card-view {
  margin-bottom: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: white;
}

.project-card-view .card-title {
  font-size: 1.5em;
  font-weight: bold;
}

.project-card-view .card-subtitle {
  font-size: 1em;
  color: #6c757d;
}



.project-card-view {
  margin-bottom: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: white;
}

.project-card-view .card-title {
  font-size: 1.5em;
  font-weight: bold;
}

.project-card-view .card-subtitle {
  font-size: 1em;
  color: #6c757d;
}
