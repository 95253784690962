.team-card {
 
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    margin: 0 auto;
  }
  
  .team-card__image {
    width: 100%;
    height: 290px;
    object-fit: cover;
   
  }
  
  .team-card__body {
    margin-top: 40px;
  }
  
  .team-card__name {
    font-size: 1.5em;
    color: #141f39;
    margin: 10px 0;
  }
  
  .team-card__title {
    font-size: 1.2em;
    color:#68187a;
    margin: 0;
  }
  